import React, { useState, useEffect, useRef } from "react";

import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";

import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import {
	Chip,
	Button,
	TextField,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	CircularProgress,
	IconButton,
	InputAdornment,
	Paper,
	Typography,
	Accordion,
	AccordionSummary,
	AccordionDetails,
	FormControlLabel,
	Switch,
} from "@material-ui/core";

import {
	Timeline,
	TimelineItem,
	TimelineSeparator,
	TimelineConnector,
	TimelineContent,
	TimelineDot
} from '@material-ui/lab';

import {
	Colorize,
	ExpandMore,
	Add,
	Delete,
	Edit,
	Done,
	Close,
} from '@material-ui/icons';


import { i18n } from "../../translate/i18n";

import api from "../../services/api";
import toastError from "../../errors/toastError";
import ColorPicker from "../ColorPicker";
import ConfirmationModal from "../../components/ConfirmationModal";
import { messageVars } from '../../services/helpers'

const useStyles = makeStyles(theme => ({
	root: {
		display: "flex",
		flexWrap: "wrap",
	},
	textField: {
		marginRight: theme.spacing(1),
		flex: 1,
	},

	btnWrapper: {
		position: "relative",
	},

	buttonProgress: {
		color: green[500],
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12,
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
	},
	colorAdorment: {
		width: 20,
		height: 20,
	},
	messageVar: {
		margin: '0 4px'
	},
	heading: {
		fontSize: theme.typography.pxToRem(15),
		fontWeight: theme.typography.fontWeightRegular,
	},

	vars: {
		border: '1px solid #DDD',
		borderRadius: '4px',
		marginTop: '10px',
		padding: '15px 10px',
	},

	switch: {
		marginTop: '15px'
	}
}));

const QueueSchema = Yup.object().shape({
	name: Yup.string()
		.min(2, "Too Short!")
		.max(50, "Too Long!")
		.required("Required"),
	color: Yup.string().min(3, "Too Short!").max(9, "Too Long!").required(),
	greetingMessage: Yup.string(),
});

const QueueModal = ({ open, onClose, queueId }) => {
	const classes = useStyles();

	const initialState = {
		name: "",
		color: "",
		greetingMessage: "",
		showOptions: false,
		options: [],
	};

	const [colorPickerModalOpen, setColorPickerModalOpen] = useState(false);
	const [queue, setQueue] = useState(initialState);
	const greetingRef = useRef();
	const [fieldMessageVars, setFieldMessageVars] = useState('greetingMessage');
	const [listExpandedOptions, setListExpandedOptions] = useState([]);
	const [selectedOptionForDelete, setSelectedOptionForDelete] = useState(null);
	const [confirmModalOpen, setConfirmModalOpen] = useState(false);

	useEffect(() => {
		(async () => {
			if (!queueId) return;
			try {
				const { data } = await api.get(`/queue/${queueId}`);
				setQueue(prevState => {
					return { ...prevState, ...data };
				});
			} catch (err) {
				toastError(err);
			}
		})();



		return () => {
			setListExpandedOptions([])
			setQueue({
				name: "",
				color: "",
				greetingMessage: "",
				showOptions: false,
				options: [],
				// showOptions: true,
				// options: [
				// 	{
				// 		id: 1,
				// 		key: 1,
				// 		name: 'option 1',
				// 		parentId: null,
				// 		response: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Mollitia quis earum officiis quam accusamus quo porro maxime nesciunt totam tenetur.',
				// 	},
				// ],
			});
		};
	}, [queueId, open]);

	const handleClose = () => {
		onClose();
		setQueue(initialState);
	};

	const handleSaveQueue = async values => {
		try {
			if (queueId) {
				await api.put(`/queue/${queueId}`, values);
			} else {
				await api.post("/queue", values);
			}
			toast.success("Queue saved successfully");
			handleClose();
		} catch (err) {
			toastError(err);
		}
	};

	const handleMessageVarBadge = (values, name) => {
		if (!fieldMessageVars) return
		let fieldEle = document.querySelector(`[name="${fieldMessageVars}"]`)
		let curPos = fieldEle.selectionStart
		let varName = '{{' + name + '}}'
		let newData = {...values, [fieldMessageVars]: fieldEle.value.slice(0, curPos) + varName + fieldEle.value.slice(curPos)}
		setQueue(newData)
	}


	const optionsTransform = (options, parent = null) => {
		if (options && Array.isArray(options)) {
			let lastOption = options.length ? options[options.length - 1] : null
			let key = lastOption && lastOption.id && lastOption.key ? +lastOption.key + 1 : 1
			let addOption = {
				key,
				parentId: parent?.id,
				name: '',
				showForm: false,
				errorName: '',
			}
			if ((options.length && key != 1) || (!options.length && key == 1)) {
				options.push(addOption)
			}
			return options.map(option => {
				option.allowEditResponse = option.allowEditResponse ?? false
				option.errorResponse = option.errorResponse ?? ''
				option.options = option.options ?? []
				return option
			})
		}
		return []
	}

	const OptionsComponent = ({options, parent}) => {
		let newOptions = optionsTransform(options, parent)
		return newOptions.map(option => {
			return (
				<Timeline>
					<TimelineItem>
						<TimelineSeparator>
							<TimelineDot>
								{option.key}
							</TimelineDot>
							{option.id ? <TimelineConnector /> : ''}
						</TimelineSeparator>
						<TimelineContent>
							{
								option.id ? (
									<Accordion expanded={listExpandedOptions.includes(option.id)} onChange={handleChangeAccordionOptions(option)}>
										<AccordionSummary
												expandIcon={<ExpandMore />}
												aria-controls="panel1a-content"
												id="panel1a-header"
											>
												<div className="option-heading">
													{option.showForm ?
														<div className="form-options spacing" onClick={(e) => e.stopPropagation()}>
															<TextField
																className={classes.textField + ' inputs'}
																label="Option name"
																variant="outlined"
																onChange={(e) => handleChangeOptionFormName(e, option)}
																defaultValue={option.name}
																multiline
																maxRows={4}
																error={Boolean(option.errorName)}
																helperText={option.errorName}
															/>
															<IconButton aria-label="done" color="primary" onClick={(e) => handleSaveOption(e, option)}>
																<Done />
															</IconButton>
															<IconButton aria-label="close" color="secondary" onClick={(e) => handleCloseFormNameOption(e, option)}>
																<Close />
															</IconButton>
														</div>
													: 
														<div className="option-name">
															<Typography className={classes.heading}>{option.name}</Typography>
															<IconButton className="btn-icon-form" aria-label="edit" color="primary" onClick={(e) => handleOpenFormNameOption(e, option)}>
																<Edit />
															</IconButton>
														</div>
													}
													<div>
														<IconButton className="btn-icon-form" aria-label="delete" color="primary" onClick={(e) => handleOpenConfirmationDeleteOption(e, option)}>
															<Delete />
														</IconButton>
													</div>
												</div>
										</AccordionSummary>
										<AccordionDetails>
											<div className="response">
												<Typography className="label">Response</Typography>
												<div className="response-formline">
													<TextField
														multiline
														maxRows={4}
														variant="outlined"
														fullWidth
														onChange={(e) => handleChangeOptionFormResponse(e, option)}
														defaultValue={option.response}
														InputProps={{
															readOnly: !option.allowEditResponse,
														}}
														error={Boolean(option.errorResponse)}
														helperText={option.errorResponse}
													/>
													{option.allowEditResponse ?
													<>
														<IconButton className="btn-icon-form" aria-label="done" color="primary" onClick={() => handleSaveResponse(option)}>
															<Done />
														</IconButton>
														<IconButton className="btn-icon-form" aria-label="close" color="secondary" onClick={() => handleCloseEditResponse(option)}>
															<Close />
														</IconButton>
													</> : 
														<IconButton className="btn-icon-form" aria-label="edit" color="primary" onClick={() => handleOpenEditResponse(option)}>
															<Edit />
														</IconButton>
													}
												</div>
											</div>
											{
												Boolean(option.response) &&
												<div className="options">
													<OptionsComponent options={option.options} parent={option} />
												</div>
											}
										</AccordionDetails>
									</Accordion>
								) : (
									<div className="add-options-form">
										{option.showForm ?
											<div className="form-options">
												<TextField
													className={classes.textField + ' inputs'}
													label="Option name"
													variant="outlined"
													onChange={(e) => handleChangeOptionFormName(e, option)}
													defaultValue={option.name}
													multiline
													maxRows={4}
													error={Boolean(option.errorName)}
													helperText={option.errorName}
												/>
												<IconButton aria-label="done" color="primary" onClick={(e) => handleSaveOption(e, option)}>
													<Done />
												</IconButton>
												<IconButton aria-label="close" color="secondary" onClick={(e) => handleCloseFormNameOption(e, option)}>
													<Close />
												</IconButton>
											</div>
										:
											<div className="add-option-btn">
												<Button color="primary" startIcon={<Add />} onClick={(e) => handleOpenFormNameOption(e, option)}>Add New Option</Button>
											</div>
										}
									</div>
								)
							}
						</TimelineContent>
					</TimelineItem>
				</Timeline>
			)
		})
	}

	const handleOpenFormNameOption = (e, option) => {
		e.stopPropagation()
		option.showForm = true
		option.errorName = ''
		setQueue({...queue})
	}
	
	const handleCloseFormNameOption = (e, option) => {
		e.stopPropagation()
		option.showForm = false
		option.errorName = ''
		setQueue({...queue})
	}
	
	const handleOpenConfirmationDeleteOption = (e, option) => {
		e.stopPropagation()
		setSelectedOptionForDelete(option)
		setConfirmModalOpen(true)
	}
	
	const handleDeleteOption = (id) => {
		api.delete(`/queue-bot/${id}`).then(res => {
			const newQueue = res.data.data.queue
			setQueue({...newQueue})
			toast.success("Queue Option has been deleted successfully")
		}).catch(err => {
			if (err.response.status == 400) {
				toastError(err)
			}
		})
	}
	
	const handleChangeOptionFormName = (e, option) => {
		option.name = e.target.value
	}

	const handleChangeOptionFormResponse = (e, option) => {
		option.response = e.target.value
	}

	const handleSaveOption = async (e, option) => {
		if (!option.name.trim()) {
			option.errorName = i18n.t("backendErrors.ERR_OPTION_REQUIRED_NAME")
			setQueue({...queue})
			return
		}
		const requestData = {
			id: option.id,
			name: option.name,
			key: option.key,
			parentId: option.parentId
		}
		api.post(`/queue/${queueId}/option`, requestData).then(res => {
			const newQueue = res.data.data.queue
			handleCloseFormNameOption(e, option)
			setQueue({...newQueue})
		}).catch(err => {
			if (err.response.status == 400) {
				toastError(err)
				if (err.response.data && err.response.data.error) {
					option.errorName = i18n.t("backendErrors." + err.response.data.error)
					setQueue({...queue})
				}
			}
		})
	}

	const handleOpenEditResponse = (option) => {
		option.allowEditResponse = true
		option.errorResponse = ''
		setQueue({...queue})
	}
	
	const handleCloseEditResponse = (option) => {
		option.allowEditResponse = false
		option.errorResponse = ''
		setQueue({...queue})
	}
	
	const handleSaveResponse = (option) => {
		if (!option.response.trim()) {
			option.errorResponse = i18n.t("backendErrors.ERR_OPTION_REQUIRED_RESPONSE")
			setQueue({...queue})
			return
		}
		const requestData = {
			response: option.response,
		}
		api.put(`/queue-bot/${option.id}`, requestData).then(res => {
			const newQueue = res.data.data.queue
			handleCloseEditResponse(option)
			setQueue({...newQueue})
		}).catch(err => {
			if (err.response.status == 400) {
				toastError(err)
				if (err.response.data && err.response.data.error) {
					option.errorResponse = i18n.t("backendErrors." + err.response.data.error)
					setQueue({...queue})
				}
			}
		})
	}

	const handleChangeAccordionOptions = (option) => (e, newExtended) => {
		let myList = [...listExpandedOptions]
		let indexOption = myList.indexOf(option.id)
		if (newExtended) {
			if (indexOption === -1) {
				myList.push(option.id)
			}
		} else {
			if (indexOption > -1) {
				myList.splice(indexOption, 1)
			}
		}
		setListExpandedOptions(myList)
	}

	const handleCloseConfirmationModal = () => {
		setConfirmModalOpen(false);
		setSelectedOptionForDelete(null);
	};

	return (
		<div className={classes.root}>
			<Dialog
				open={open}
				onClose={handleClose}
				scroll="paper"
				maxWidth="lg"
				fullWidth
			>
				<DialogTitle>
					{queueId
						? `${i18n.t("queueModal.title.edit")}`
						: `${i18n.t("queueModal.title.add")}`}
				</DialogTitle>
				<Formik
					initialValues={queue}
					enableReinitialize={true}
					validationSchema={QueueSchema}
					onSubmit={(values, actions) => {
						setTimeout(() => {
							handleSaveQueue(values);
							actions.setSubmitting(false);
						}, 400);
					}}
				>
					{({ touched, errors, isSubmitting, values }) => (
						<Form>
							<DialogContent dividers>
								<Field
									as={TextField}
									label={i18n.t("queueModal.form.name")}
									autoFocus
									name="name"
									error={touched.name && Boolean(errors.name)}
									helperText={touched.name && errors.name}
									variant="outlined"
									margin="dense"
									className={classes.textField}
								/>
								<Field
									as={TextField}
									label={i18n.t("queueModal.form.color")}
									name="color"
									id="color"
									onFocus={() => {
										setColorPickerModalOpen(true);
										greetingRef.current.focus();
									}}
									error={touched.color && Boolean(errors.color)}
									helperText={touched.color && errors.color}
									InputProps={{
										startAdornment: (
											<InputAdornment position="start">
												<div
													style={{ backgroundColor: values.color }}
													className={classes.colorAdorment}
												></div>
											</InputAdornment>
										),
										endAdornment: (
											<IconButton
												size="small"
												color="default"
												onClick={() => setColorPickerModalOpen(true)}
											>
												<Colorize />
											</IconButton>
										),
									}}
									variant="outlined"
									margin="dense"
								/>
								<ColorPicker
									open={colorPickerModalOpen}
									handleClose={() => setColorPickerModalOpen(false)}
									onChange={color => {
										values.color = color;
										setQueue(() => {
											return { ...values, color };
										});
									}}
								/>
								<div>
									<Field
										as={TextField}
										label={i18n.t("queueModal.form.greetingMessage")}
										type="greetingMessage"
										multiline
										inputRef={greetingRef}
										rows={5}
										fullWidth
										name="greetingMessage"
										error={
											touched.greetingMessage && Boolean(errors.greetingMessage)
										}
										helperText={
											touched.greetingMessage && errors.greetingMessage
										}
										variant="outlined"
										margin="dense"
										onFocus={() => setFieldMessageVars('greetingMessage')}
									/>
								</div>

								<div className={classes.vars}>
									{
										messageVars.map(varName => {
											return (<Chip className={classes.messageVar} label={varName}  onClick={() => handleMessageVarBadge(values, varName)} />)
										})
									}
								</div>
								{ queueId ?
									<FormControlLabel
										className={classes.switch}
										control={
											<Switch
												checked={queue.showOptions}
												onChange={(e) => {
													setQueue({...queue, showOptions: e.target.checked})
												}}
												name="showOptions"
												color="primary"
											/>
										}
										label={i18n.t("queueModal.form.showOptions")}
									/>
									:
									<div className="placeholder-options">
										You must create the queue first and then click to edit to add the bot.
									</div>
								}

								{/* ============================== ./Options ============================== */}
								{

									queueId && values.showOptions && 
									<div className="bot-options">
										<OptionsComponent options={values.options} />
									</div>
								}
								{/* ============================== ./Options ============================== */}
							</DialogContent>
							<DialogActions>
								<Button
									onClick={handleClose}
									color="secondary"
									disabled={isSubmitting}
									variant="outlined"
								>
									{i18n.t("queueModal.buttons.cancel")}
								</Button>
								<Button
									type="submit"
									color="primary"
									disabled={isSubmitting}
									variant="contained"
									className={classes.btnWrapper}
								>
									{queueId
										? `${i18n.t("queueModal.buttons.okEdit")}`
										: `${i18n.t("queueModal.buttons.okAdd")}`}
									{isSubmitting && (
										<CircularProgress
											size={24}
											className={classes.buttonProgress}
										/>
									)}
								</Button>
							</DialogActions>
						</Form>
					)}
				</Formik>
			</Dialog>

			<ConfirmationModal
				title={selectedOptionForDelete && `${i18n.t("queues.confirmationModal.deleteTitle")} ${selectedOptionForDelete.name}?`}
				open={confirmModalOpen}
				onClose={handleCloseConfirmationModal}
				onConfirm={() => handleDeleteOption(selectedOptionForDelete.id)}
			>
				{i18n.t("queues.confirmationModal.deleteBotOptionMessage")}
			</ConfirmationModal>
		</div>
	);
};

export default QueueModal;
