// import socket from "socket.io-client";
import socket from "socket.io-client";
import { backendFullUrl, tenantSubdomain } from './urls'

export const openSocket = () => {
    // return socket(backendFullUrl + '/' + tenantSubdomain)
    return socket(backendFullUrl)
}

export const socketWithTenant = (key) => {
    return tenantSubdomain + '_' + key
}