import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";

import { toast } from "react-toastify";

import clsx from "clsx";

import { Paper, makeStyles, Switch, FormGroup, FormControlLabel, Grid, Box } from "@material-ui/core";

import ContactDrawer from "../ContactDrawer";
import MessageInput from "../MessageInput/";
import TicketHeader from "../TicketHeader";
import TicketInfo from "../TicketInfo";
import TicketActionButtons from "../TicketActionButtons";
import MessagesList from "../MessagesList";
import api from "../../services/api";
import { ReplyMessageProvider } from "../../context/ReplyingMessage/ReplyingMessageContext";
import toastError from "../../errors/toastError";
import axios from 'axios';
import { backendFullUrl, tenantSubdomain } from '../../services/urls' /* Edit By Algoriza */

const drawerWidth = 320;

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    display: "flex",
    height: "100%",
    position: "relative",
    overflow: "hidden",
  },

  ticketInfo: {
    backgroundColor: theme.palette.background.default,
    maxWidth: "50%",
    flexBasis: "50%",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "80%",
      flexBasis: "80%",
    },
  },
  ticketActionButtons: {
    backgroundColor: theme.palette.background.default,
    maxWidth: "50%",
    flexBasis: "50%",
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
      flexBasis: "100%",
      marginBottom: "5px",
    },
  },

  mainWrapper: {
    flex: 1,
    height: "100%",
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    borderLeft: "0",
    marginRight: -drawerWidth,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },

  mainWrapperShift: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },
  button: {
		position: "relative",
		backgroundColor: "green",
    fontSize: "10px",
    margin: "35px",
    marginLeft: "2px",
    marginRight: "2px",
    color: "#FFF",
    width: "5%",
    '&:hover': {
      backgroundColor: "red",
      color: "#FFF"
    },    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
      flexBasis: "100%",
      margin: "5px",
      marginLeft: "50px",
      marginRight: "50px",
      marginBottom: "5px",
    },
	},
  box: {
    backgroundColor: theme.palette.background.default,
		position: "relative",
    marginLeft: "2px",
    marginRight: "2px",
    [theme.breakpoints.down("sm")]: {
      display: "none"
    },
	},
}));

const Ticket = () => {

  const [checked1, setChecked1] = React.useState(false);
  const [checked2, setChecked2] = React.useState(false);
  const [checked3, setChecked3] = React.useState(false);
  const [checked4, setChecked4] = React.useState(false);
  const [checked5, setChecked5] = React.useState(false);

  const { ticketId } = useParams();
  const history = useHistory();
  const classes = useStyles();

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [contact, setContact] = useState({});
  const [ticket, setTicket] = useState({});

  const DialogFlowOn = (async () => {
    const url = backendFullUrl + '/zdgDialogFlowOn';
    const number = `${contact.number}`;
    const headers = {
      'Content-Type': 'application/json; charset=utf-8'
    };
    const data = {
      msgFrom: number
    };
  
    try {
      const response = await axios.post(url, data, { headers });
      alert(`DialogFlow ON: ${contact.name} - WPP: ${contact.number} Ticket: #${ticket.id}`);
      console.log(response);
    } catch (error) {
      console.error(error);
    }
  })

  const DialogFlowOff = (async () => {
    const url = backendFullUrl + '/zdgDialogFlowOff';
    const number = `${contact.number}`;
    const headers = {
      'Content-Type': 'application/json; charset=utf-8'
    };
    const data = {
      msgFrom: number
    };
  
    try {
      const response = await axios.post(url, data, { headers });
      alert(`DialogFlow OFF: ${contact.name} - WPP: ${contact.number} Ticket: #${ticket.id}`);
      console.log(response);
    } catch (error) {
      console.error(error);
    }
  })

  const DialogFlowOnAudio = (async () => {
    const url = backendFullUrl + '/zdgDialogFlowOnAudio';
    const number = `${contact.number}`;
    const headers = {
      'Content-Type': 'application/json; charset=utf-8'
    };
    const data = {
      msgFrom: number
    };
  
    try {
      const response = await axios.post(url, data, { headers });
      alert(`DialogFlow AUDIO ON: ${contact.name} - WPP: ${contact.number} Ticket: #${ticket.id}`);
      console.log(response);
    } catch (error) {
      console.error(error);
    }
  })

  const DialogFlowOffAudio = (async () => {
    const url = backendFullUrl + '/zdgDialogFlowOffAudio';
    const number = `${contact.number}`;
    const headers = {
      'Content-Type': 'application/json; charset=utf-8'
    };
    const data = {
      msgFrom: number
    };
  
    try {
      const response = await axios.post(url, data, { headers });
      alert(`DialogFlow AUDIO OFF: ${contact.name} - WPP: ${contact.number} Ticket: #${ticket.id}`);
      console.log(response);
    } catch (error) {
      console.error(error);
    }
  })

  const ChatBotOn = (async () => {
    const url = backendFullUrl + '/zdgChatBotOn';
    const number = `${contact.number}`;
    const headers = {
      'Content-Type': 'application/json; charset=utf-8'
    };
    const data = {
      msgFrom: number
    };
  
    try {
      const response = await axios.post(url, data, { headers });
      alert(`MYSQL ON: ${contact.name} - WPP: ${contact.number} Ticket: #${ticket.id}`);
      console.log(response);
    } catch (error) {
      console.error(error);
    }
  })

  const ChatBotOff = (async () => {
    const url = backendFullUrl + '/zdgChatBotOff';
    const number = `${contact.number}`;
    const headers = {
      'Content-Type': 'application/json; charset=utf-8'
    };
    const data = {
      msgFrom: number
    };
  
    try {
      const response = await axios.post(url, data, { headers });
      alert(`MYSQL OFF: ${contact.name} - WPP: ${contact.number} Ticket: #${ticket.id}`);
      console.log(response);
    } catch (error) {
      console.error(error);
    }
  })

  const N8NOn = (async () => {
    const url = backendFullUrl + '/zdgN8NOn';
    const number = `${contact.number}`;
    const headers = {
      'Content-Type': 'application/json; charset=utf-8'
    };
    const data = {
      msgFrom: number
    };
  
    try {
      const response = await axios.post(url, data, { headers });
      alert(`N8N ON: ${contact.name} - WPP: ${contact.number} Ticket: #${ticket.id}`);
      console.log(response);
    } catch (error) {
      console.error(error);
    }
  })

  const N8NOff = (async () => {
    const url = backendFullUrl + '/zdgN8NOff';
    const number = `${contact.number}`;
    const headers = {
      'Content-Type': 'application/json; charset=utf-8'
    };
    const data = {
      msgFrom: number
    };
  
    try {
      const response = await axios.post(url, data, { headers });
      alert(`N8N OFF: ${contact.name} - WPP: ${contact.number} Ticket: #${ticket.id}`);
      console.log(response);
    } catch (error) {
      console.error(error);
    }
  })

  const GPTOn = (async () => {
    const url = backendFullUrl + '/zdgGPTOn';
    const number = `${contact.number}`;
    const headers = {
      'Content-Type': 'application/json; charset=utf-8'
    };
    const data = {
      msgFrom: number
    };
  
    try {
      const response = await axios.post(url, data, { headers });
      alert(`ChatGPT ON: ${contact.name} - WPP: ${contact.number} Ticket: #${ticket.id}`);
      console.log(response);
    } catch (error) {
      console.error(error);
    }
  })

  const GPTOff = (async () => {
    const url = backendFullUrl + '/zdgGPTOff';
    const number = `${contact.number}`;
    const headers = {
      'Content-Type': 'application/json; charset=utf-8'
    };
    const data = {
      msgFrom: number
    };
  
    try {
      const response = await axios.post(url, data, { headers });
      alert(`ChatGPT OFF: ${contact.name} - WPP: ${contact.number} Ticket: #${ticket.id}`);
      console.log(response);
    } catch (error) {
      console.error(error);
    }
  })

  const getN8N = (async (msgFrom) => {
		const url = backendFullUrl + '/getn8nstatus/' + msgFrom;
		try {
			const response = await axios.get(url);
			const setChecked = response.data.toString().replace(/"/g, "");
      if(setChecked === "ok"){
        setChecked4(true)
      }
      if(setChecked === "off"){
        setChecked4(false)
      }
		} catch (error) {
			alert("Erro: " + error.message);
		}
	})

  const getChatBot = (async (msgFrom) => {
		const url = backendFullUrl + '/getchatbotstatus/' + msgFrom;
		try {
			const response = await axios.get(url);
			const setChecked = response.data.toString().replace(/"/g, "");
      if(setChecked === "ok"){
        setChecked2(true)
      }
      if(setChecked === "off"){
        setChecked2(false)
      }
		} catch (error) {
			alert("Erro: " + error.message);
		}
	})

  const getDialog = (async (msgFrom) => {
		const url = backendFullUrl + '/getdialogstatus/' + msgFrom;
		try {
			const response = await axios.get(url);
			const setChecked = response.data.toString().replace(/"/g, "");
      if(setChecked === "ok"){
        setChecked1(true)
      }
      if(setChecked === "off"){
        setChecked1(false)
      }
		} catch (error) {
			alert("Erro: " + error.message);
		}
	})

  const getDialogAudio = (async (msgFrom) => {
		const url = backendFullUrl + '/getdialogaudiostatus/' + msgFrom;
		try {
			const response = await axios.get(url);
			const setChecked = response.data.toString().replace(/"/g, "");
      if(setChecked === "ok"){
        setChecked3(true)
      }
      if(setChecked === "off"){
        setChecked3(false)
      }
		} catch (error) {
			alert("Erro: " + error.message);
		}
	})

  const getGPT = (async (msgFrom) => {
		const url = backendFullUrl + '/getgptstatus/' + msgFrom;
		try {
			const response = await axios.get(url);
			const setChecked = response.data.toString().replace(/"/g, "");
      if(setChecked === "ok"){
        setChecked5(true)
      }
      if(setChecked === "off"){
        setChecked5(false)
      }
		} catch (error) {
			alert("Erro: " + error.message);
		}
	})

  useEffect(() => {
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      const fetchTicket = async () => {
        try {
          const { data } = await api.get("/tickets/" + ticketId);
          setContact(data.contact);
          setTicket(data);
          setLoading(false);
          getN8N(JSON.stringify(data.contact.number).replace(/"/g, ""))
          getChatBot(JSON.stringify(data.contact.number).replace(/"/g, ""))
          getDialog(JSON.stringify(data.contact.number).replace(/"/g, ""))
          getDialogAudio(JSON.stringify(data.contact.number).replace(/"/g, ""))
          getGPT(JSON.stringify(data.contact.number).replace(/"/g, ""))
        } catch (err) {
          setLoading(false);
          toastError(err);
        }
      };
      fetchTicket();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [ticketId, history]);

  useEffect(() => {
    const socket = window.$socket.openSocket()
    socket.on("connect", () => socket.emit('joinChatBox', {subdomain: tenantSubdomain, ticketId}));
    socket.on(window.$socket.socketWithTenant("ticket"), (data) => {
      if (data.action === "update") {
        setTicket(data.ticket);
      }
      if (data.action === "delete") {
        toast.success("Ticket deleted sucessfully.");
        history.push("/tickets");
      }
    });
    socket.on(window.$socket.socketWithTenant("contact"), (data) => {
      if (data.action === "update") {
        setContact((prevState) => {
          if (prevState.id === data.contact?.id) {
            return { ...prevState, ...data.contact };
          }
          return prevState;
        });
      }
    });

    return () => {
      socket.disconnect();
    };
  }, [ticketId, history]);

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const handleChange = (event) => {
    setChecked1(event.target.checked);
    if(checked1 === false)
    {
      DialogFlowOn();
    }
    else if (checked1 === true ){
      DialogFlowOff();
    }
  };

  const handleChange2 = (event) => {
    setChecked2(event.target.checked);
    if(checked2 === false)
    {
      ChatBotOn();
    }
    else if (checked2 === true ){
      ChatBotOff();
    }
  };

  const handleChange3 = (event) => {
    setChecked3(event.target.checked);
    if(checked3 === false)
    {
      DialogFlowOnAudio();
    }
    else if (checked3 === true ){
      DialogFlowOffAudio();
    }
  };

  const handleChange4 = (event) => {
    setChecked4(event.target.checked);
    if(checked4 === false)
    {
      N8NOn();
    }
    else if (checked4 === true ){
      N8NOff();
    }
  };

  const handleChange5 = (event) => {
    setChecked5(event.target.checked);
    if(checked5 === false)
    {
      GPTOn();
    }
    else if (checked5 === true ){
      GPTOff();
    }
  };

  return (
    <div className={classes.root} id="drawer-container">
      <Paper
        variant="outlined"
        elevation={0}
        className={clsx(classes.mainWrapper, {
          [classes.mainWrapperShift]: drawerOpen,
        })}
      >
        <TicketHeader loading={loading}>
          <div className={classes.ticketInfo}>
            <TicketInfo
              contact={contact}
              ticket={ticket}
              onClick={handleDrawerOpen}
            />
          </div>
          <div className={classes.ticketActionButtons}>
            <TicketActionButtons ticket={ticket} />
          </div>
        </TicketHeader>
        {/* <Box className={classes.box}>
        <Grid container spacing={1} style={{display:'flex', alignItems:'center', textAlign: 'center', padding:'10px'}}>
          <Grid item xs={12} md={2} sm={2}>
          <FormGroup row>
          <FormControlLabel control={
          <Switch
            checked={checked1}
            onChange={handleChange}
            inputProps={{ 'aria-label': 'controlled' }}
          />}label="DialogFlow" />
          </FormGroup>
          </Grid>
          <Grid item xs={12} md={3} sm={2}>
          <FormGroup row>
          <FormControlLabel control={
          <Switch
            checked={checked3}
            onChange={handleChange3}
            inputProps={{ 'aria-label': 'controlled' }}
          />}label="DialogFlowAudio" />
          </FormGroup>
          </Grid>
          <Grid item xs={12} md={2} sm={2}>
          <FormGroup row>
          <FormControlLabel control={
          <Switch
            checked={checked2}
            onChange={handleChange2}
            inputProps={{ 'aria-label': 'controlled' }}
          />}label="MYSQL" />
          </FormGroup>
          </Grid>
          <Grid item xs={12} md={2} sm={2}>
          <FormGroup row>
          <FormControlLabel control={
          <Switch
            checked={checked4}
            onChange={handleChange4}
            inputProps={{ 'aria-label': 'controlled' }}
          />}label="N8N" />
          </FormGroup>
          </Grid>
          <Grid item xs={12} md={2} sm={2}>
          <FormGroup row>
          <FormControlLabel control={
          <Switch
            checked={checked5}
            onChange={handleChange5}
            inputProps={{ 'aria-label': 'controlled' }}
          />}label="ChatGPT" />
          </FormGroup>
          </Grid>
        </Grid>
        </Box> */}
        <ReplyMessageProvider>
          <MessagesList
            ticketId={ticketId}
            isGroup={ticket.isGroup}
          ></MessagesList>
          <MessageInput ticketStatus={ticket.status} />
        </ReplyMessageProvider>
      </Paper>
      <ContactDrawer
        open={drawerOpen}
        handleDrawerClose={handleDrawerClose}
        contact={contact}
        loading={loading}
      />
    </div>
  );
};

export default Ticket;
