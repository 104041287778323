import React, { useState, useContext } from "react";
import { Link as RouterLink } from "react-router-dom";

// import axios from "axios";

import {
  // Avatar,
  Button,
  CssBaseline,
  TextField,
  Grid,
  Box,
  Typography,
  // Container,
  InputAdornment,
  IconButton,
  Link
} from '@material-ui/core';

import { Visibility, VisibilityOff } from '@material-ui/icons';

import { makeStyles } from "@material-ui/core/styles";

import { i18n } from "../../translate/i18n";

import { AuthContext } from "../../context/Auth/AuthContext";

// const Copyright = () => {
// 	return (
// 		<Typography variant="body2" color="textSecondary" align="center">
// 			{"Copyleft "}
// 			<Link color="inherit" href="https://github.com/canove">
// 				Canove
// 			</Link>{" "}
// 			{new Date().getFullYear()}
// 			{"."}
// 		</Typography>
// 	);
// };

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const Login = () => {
  const classes = useStyles();

  const [user, setUser] = useState({ email: "", password: "" });
  const [showPassword, setShowPassword] = useState(false);

  const { handleLogin } = useContext(AuthContext);

  const handleChangeInput = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const handlSubmit = (e) => {
    e.preventDefault();
    handleLogin(user);
    // try {
    //   const datapost = {
    //     email : user.email,
    //     password : user.password,
    //   }
    //   axios.post("http://localhost:5000/api/auth/login", datapost)
    //   .then(response=>{
    //     if (response.data.success===true) {
    //     console.log(response.data.message)
    //     }
    //     else {
    //     console.log("Error" + JSON.stringify(response))
    //     }
    //   }).catch(error=>{
    //     console.log("Error 34 "+error)
    //   })
    // } catch(e){
    //   console.log(e)
    // }
  };

  return (
    <div className={"d-flex flex-column flex-root"}>
      <div className={"d-flex flex-column flex-lg-row flex-column-fluid"}>
        <div className={"d-flex flex-column flex-lg-row-fluid w-lg-50 p-10 order-2 order-lg-1"}>
          <div className={"d-flex flex-center flex-column flex-lg-row-fluid"}>
            <div className={"w-lg-500px p-10"}>
              <div className={classes.paper + " al-login-container"}>
        <Typography component="h1" variant="h5">
          {i18n.t("login.title")}
        </Typography>

                <form className={classes.form + " al-login-form"} noValidate onSubmit={handlSubmit}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label={i18n.t("login.form.email")}
            name="email"
            value={user.email}
            onChange={handleChangeInput}
            autoComplete="email"
            autoFocus
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label={i18n.t("login.form.password")}
            id="password"
            value={user.password}
            onChange={handleChangeInput}
            autoComplete="current-password"
            type={showPassword ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword((e) => !e)}
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            {i18n.t("login.buttons.submit")}
          </Button>
          <Grid container>
                    <Grid item className={'m-auto'}>
              <Link
                href="#"
                variant="body2"
                component={RouterLink}
                to="/signup"
              >
                {i18n.t("login.buttons.register")}
              </Link>
            </Grid>
          </Grid>
        </form>

      </div>
      <Box mt={8}>{/* <Copyright /> */}</Box>
            </div>
          </div>
        </div>
        <div className={"d-flex flex-lg-row-fluid w-lg-50 bgi-size-cover bgi-position-center order-1 order-lg-2 al-bg-login"}>
          <div className={"d-flex flex-column flex-center py-7 py-lg-15 px-5 px-md-15 w-100"}>
            <img className={"d-none d-lg-block mx-auto w-275px w-md-50 w-xl-400px mb-10 mb-lg-20"} src={"http://bdaia.com/whatsappcrm/assets/images/login-img-2.svg"} alt="logo" />
            <h3 className={"d-none d-lg-block text-white fw-bolder text-center mb-7 text-center w-lg-50"}>Answer customer chats on WhatsApp, Messenger and Instagram from a single window and free yourself from stress.</h3>
          </div>
        </div>
      </div>
      <CssBaseline />
    </div>
  );
};

export default Login;
