import axios from "axios";
import React from "react";
import ReactDOM from "react-dom";
import CssBaseline from "@material-ui/core/CssBaseline";

import App from "./App";

import { tenantSubdomain } from './services/urls'

// ReactDOM.render(
// 	<CssBaseline  >
// 		<App />
// 	</CssBaseline>,
// 	document.getElementById("root")
// );

if (tenantSubdomain) {
	axios.post(process.env.REACT_APP_WEBSITE_URL + '/api/v1/organizations/check', {domain: tenantSubdomain}).then(res => {
		// console.log(res.data.data)
		ReactDOM.render(
			<CssBaseline  >
				<App />
			</CssBaseline>,
			document.getElementById("root")
		);
	}).catch(err => {
		console.log(err)
		window.location.replace(process.env.REACT_APP_WEBSITE_URL)
	})
} else {
	window.location.replace(process.env.REACT_APP_WEBSITE_URL)
}