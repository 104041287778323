import React from "react";


import {
  makeStyles,
} from "@material-ui/core";

import { backendFullUrl } from '../../services/urls'

const useStyles = makeStyles((theme) => ({
  iframe: {
    height: '100%',
    width: '100%'
  }
}));

const Apis = () => {
  const classes = useStyles();

  return (
    <iframe className={classes.iframe} frameBorder="0" src={backendFullUrl + '/docs-to-view-in-iframe-d32awna435u4w35hd345awd734asd7q23rh7as732r27rg23rg74'}></iframe>
  );
};

export default Apis;
