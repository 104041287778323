import axios from "axios";

import { backendFullUrl } from './urls'

const api = axios.create({
	baseURL: backendFullUrl,
	withCredentials: true,
});

export default api;
