
const BACKEND_URL = process.env.REACT_APP_BACKEND_URL
const CHATBOT_URL = process.env.REACT_APP_CHATBOT_URL

function checkAndGetTenantSubdomain() {
    let subdomains = window.location.hostname.split('.')
    let currentSubdomain = ''
    if (subdomains.length > 1) {
        currentSubdomain = subdomains[0]
    } else {
        currentSubdomain = false
    }
    // if (process.env.NODE_ENV === 'development') {
    // } else { // production
    //     if (subdomains.length > 2) {
    //         currentSubdomain = subdomains[0]
    //     } else {
    //         currentSubdomain = false
    //     }
    // }
    return currentSubdomain
}

export function removeHttp(url) {
    return url.split('//')[1]
}

export function addTenantSubdomain(url) {
    const currentSubdomain = checkAndGetTenantSubdomain()
    if (currentSubdomain) {
        let urlArr = url.split('//')
        let portHttp = urlArr[0]
        let urlWithoutHtpp = urlArr[1]
        return portHttp + '//' + currentSubdomain + '.' + urlWithoutHtpp
    }
    return url
}



export const backendFullUrl = addTenantSubdomain(BACKEND_URL)

export const backendUrlWithoutHttp = removeHttp(backendFullUrl)

export const chatbotFullUrl = addTenantSubdomain(CHATBOT_URL)

export const tenantSubdomain = checkAndGetTenantSubdomain()
